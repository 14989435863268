import React, { useState, useRef, useEffect } from 'react';
import './styles.css';

function App() {
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [settlements, setSettlements] = useState([]);
  const [balances, setBalances] = useState({});
  const nameInputRef = useRef(null); // Declare the ref for the name input element


  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

 

  const handleRemoveEntry = (index) => {
    const itemToDelete = data[index];
    setName(itemToDelete.name);
    setAmount(itemToDelete.amount);
    const updatedData = [...data];
    updatedData.splice(index, 1); // Remove the entry at the specified index
    setData(updatedData);
  };

  


  const handleAddData = () => {
    if (name.trim() !== '' && !isNaN(amount)) {
      const newData = [...data, { name, amount: parseFloat(amount) }];
      setData(newData);
      setName('');
      setAmount('');
      setTimeout(() => {
        nameInputRef.current.focus();
      }, 0);
    }
  };

  useEffect(() => {
    const calculateSettlements = () => {
      const total = data.reduce((sum, item) => sum + item.amount, 0);
      const average = total / data.length;
  
      const newBalances = {};
      const newBalances2 = {};
      data.forEach((item) => {
        newBalances[item.name] = item.amount - average;
      });
      data.forEach((item) => {
        newBalances2[item.name] = item.amount;
      });
      setBalances(newBalances);
  
      const settlements = [];
  
      while (Object.keys(newBalances).length > 1) {
        const creditor = Object.keys(newBalances).find((name) => newBalances[name] > 0);
        const debtor = Object.keys(newBalances).find((name) => newBalances[name] < 0);
  
        if (!creditor || !debtor) {
          break;
        }
  
        const amountToSettle = Math.min(
          Math.abs(newBalances[creditor]),
          Math.abs(newBalances[debtor])
        );
  
        newBalances[creditor] -= amountToSettle;
        newBalances[debtor] += amountToSettle;
  
        newBalances2[creditor] -= amountToSettle;
        newBalances2[debtor] += amountToSettle;
  
        settlements.push({
          from: debtor,
          to: creditor,
          amount: amountToSettle.toFixed(2),
          fromBalance: newBalances2[debtor].toFixed(2),
          toBalance: newBalances2[creditor].toFixed(2),
          arr: { ...newBalances2 }
        });
  
  /*
        if (newBalances[creditor] === 0) {
          delete newBalances[creditor];
        }
        if (newBalances[debtor] === 0) {
          delete newBalances[debtor];
        }
        */
      }
  
      setSettlements(settlements);
    };
    calculateSettlements(); // Calculate settlements whenever data changes
  }, [data]);

  return (
    <div className="App">
      <h1>
        Wie betaalt wie!?      </h1>
      <div>
        Wie heeft wat betaald?<br></br>
        <input
          type="text"
          placeholder="Naam"
          value={name}
          onChange={handleNameChange}
          ref={nameInputRef} // Attach the ref to the name input
        />
        <input
          type="text"
          placeholder="Bedrag"
          value={amount}
          onChange={handleAmountChange}
        />
        <button onClick={handleAddData}>Toevoegen</button>
      </div>
      <div><br></br>
      <table>
  <thead>
    <tr style={{ border: '1px solid black', padding: '5px' }}>
      <th style={{ border: '1px solid black', padding: '5px' }}>Naam</th>
      {data.map((item, index) => (
        <th style={{ border: '1px solid black', padding: '5px' }} key={index}>{item.name}</th>
      ))}
    </tr>
  </thead>
  <tbody>
    <tr style={{ border: '1px solid black', padding: '5px' }}>
      <td style={{ border: '1px solid black', padding: '5px' }}>Bedrag</td>
      {data.map((item, index) => (
        <td style={{ border: '1px solid black', padding: '5px' }} key={index}>{item.amount} euro</td>
      ))}
      <td></td> {/* Empty cell for Remove button */}
    </tr>
    <tr>
      <td></td> {/* Empty cell for the Name column */}
      {data.map((item, index) => (
        <td key={index}>
          <button onClick={() => handleRemoveEntry(index)}>Verwijder</button>
        </td>
      ))}
      <td></td> {/* Empty cell for the last Remove button */}
    </tr>
  </tbody>
</table>

      </div>
<div>
  <h2>Transacties</h2>
  <div >
    <table style={{ borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid black', padding: '5px' }}>Transactie \ Balans
          </th>
          {Object.keys(balances).map((item, index) => (
            <th
              key={index}
              style={{
                border: '1px solid black',
                padding: '5px',
              }}
            >
              {item}
            </th>
          ))
            }
        </tr>
      </thead>
      <tbody>    <tr>
          <td style={{ border: '1px solid black', padding: '5px' }}>initieel</td>
       
          {data.map((item, index) => (
            <td 
            style={{
              border: '1px solid black',
              padding: '5px',
              
            }}
            key={index}>
              {item.amount}
            </td>
          ))}
          </tr>
        
        {settlements.map((settlement, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid black', padding: '5px' }}>{settlement.from} betaalt {settlement.amount} euro aan {settlement.to}</td>
            
                {Object.keys(settlement.arr).map((item, index) => (
                  
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '5px',
                        backgroundColor:
                          item === settlement.from || item === settlement.to ? 'lightblue' : 'white',
                      }}
                    >
                      {settlement.arr[item]}
                    </td>
                 
                ))}
              
            
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>



    </div>
  );
}

export default App;
